import { ListDivider } from "@/components/ListDivider";
import { UserPermission } from "@/core/models/user-permission.model";
import { selectUserPermissions } from "@/features/usersManagment/store/userPermissionsSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import { Button, Flex } from "@gogeo-io/ui-library";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { emitEventToChangeUsers } from "../store/emitEventToChangeUsersSlice";
import { UsersPermissionListItem } from "./UsersPermissionListItem";
import { UsersPermissionsNameOfUsers } from "./UsersPermissionsNameOfUsers";

interface UsersPermissionsModalContentProps {
  onCloseDialog: () => void;
  onChangePermissions: (permissions: UserPermission[]) => Promise<void>;
}

export function UsersPermissionsModalContent({
  onCloseDialog,
  onChangePermissions,
}: UsersPermissionsModalContentProps) {
  const userPermissions = useSelector(selectUserPermissions);

  const dispatch = useAppDispatch();

  const [changedPermissions, setChangedPermissions] = useState<
    UserPermission[]
  >([]);

  const handleCancel = () => {
    onCloseDialog();
  };

  const handleChangePermissions = (
    newPermission: UserPermission,
    newValue: string
  ) => {
    if (newValue === "indeterminate") {
      const permissionsWithoutRemovedOne = changedPermissions.filter(
        (permission) => permission.property !== newPermission.property
      );
      setChangedPermissions(permissionsWithoutRemovedOne);
    } else {
      if (
        changedPermissions.filter(
          (permission) => permission.title === newPermission.title
        ).length > 0
      ) {
        const permissionsWithoutRemovedOne = changedPermissions.filter(
          (permission) => permission.property !== newPermission.property
        );
        setChangedPermissions([
          ...permissionsWithoutRemovedOne,
          {
            ...newPermission,
            value: newValue === "true" ? true : false,
          },
        ]);
      } else {
        setChangedPermissions((prevPermissions) => [
          ...prevPermissions,
          {
            ...newPermission,
            value: newValue === "true" ? true : false,
          },
        ]);
      }
    }
  };

  const handleSave = async () => {
    await dispatch(emitEventToChangeUsers(false));

    onChangePermissions(changedPermissions);
    onCloseDialog();

    await dispatch(emitEventToChangeUsers(true));
  };

  return (
    <UserPermissionsContainer>
      <Flex
        css={{
          flexDirection: "column",
          gap: "$2",
          height: "100%",
        }}
      >
        <UsersPermissionsNameOfUsers />

        <PermissionsContainer>
          <Flex css={{ flexDirection: "column", gap: "$2", width: "100%" }}>
            {userPermissions.map((permission, idx) => {
              return (
                <Flex
                  css={{ flexDirection: "column", gap: "$3", width: "100%" }}
                  key={permission.property}
                >
                  <UsersPermissionListItem
                    userPermission={permission}
                    onChangePermission={handleChangePermissions}
                  />
                  {idx !== userPermissions.length - 1 && <ListDivider />}
                </Flex>
              );
            })}
          </Flex>
        </PermissionsContainer>

        <Flex
          css={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: "$2",
            mt: "$4",
          }}
        >
          <Button bordered onClick={handleCancel}>
            Cancelar
          </Button>
          <Button onClick={handleSave}>Salvar</Button>
        </Flex>
      </Flex>
    </UserPermissionsContainer>
  );
}

const UserPermissionsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: ${({ theme }) => theme.space[2]};
`;

export const PermissionsContainer = styled.div`
  display: flex;
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 0.5rem;
  margin-top: ${({ theme }) => theme.space[4]};

  @media (max-width: ${(props) => props.theme.mediaSizes.laptop}) {
    height: 380px;
  }

  @media (max-width: ${(props) => props.theme.mediaSizes.smallLaptop}) {
    height: 300px;
  }
`;
