import { ListDivider } from "@/components/ListDivider";
import { ClientsTableSkeletonPage } from "@/components/Skeleton/ClientsTableSkeletonPage";
import { TrackingEvents } from "@/config/mixpanel";
import { emitEventToChangeClients } from "@/features/attendance/store/emitEventToChangeClientsSlice";
import { FloatButtonToCreate } from "@/features/profile/components/FloatButtonToCreate";
import { UserProfileHeaderToPage } from "@/features/profile/components/UserProfileHeaderToPage";
import { useMixpanel } from "@/hooks/useMixpanel";
import {
  getSalesmansRelatedToUser,
  selectSalesmansRelatedToUser,
  selectUser,
} from "@core/store/users/usersSlice";
import {
  Box,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Flex,
  IconButton,
} from "@gogeo-io/ui-library";
import useAppDispatch from "@hooks/useAppDispatch";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { ParamsOfSalesmanForm } from "../components/ParamsOfSalesmanForm";
import { ChangeParamsOfOneSalesmanForm } from "../components/ParamsOfSalesmanForm/ChangeParamsOfOneSalesmanForm";
import { ParamsOfSalesmansTable } from "../components/ParamsOfSalesmansTable";
import { useBreadcrumbsForParamsPage } from "../hooks/useBreadcrumbsForParamsPage";
import { useParamsOfSalesmansColumns } from "../hooks/useParamsOfSalesmansColumns";
import {
  selectSelectedSalesmansToChangeParams,
  setSelectedSalesmansToChangeParams,
} from "../store/paramsSlice";

export function ChangeParamsOfSalesmanPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const user = useSelector(selectUser);
  const salesmansRelatedToUser = useSelector(selectSalesmansRelatedToUser);
  const selectedSalesmansToChangeParams = useSelector(
    selectSelectedSalesmansToChangeParams
  );

  const dispatch = useAppDispatch();
  const { breadcrumbsForSalesmanParams } = useBreadcrumbsForParamsPage();
  const { pageViewed } = useMixpanel();

  const loadSalesmansRelatedToUser = async () => {
    await dispatch(emitEventToChangeClients(false));

    if (user.role === "SALESMAN") {
      setIsLoading(false);
      await dispatch(emitEventToChangeClients(true));
    } else {
      await dispatch(getSalesmansRelatedToUser({ user_id: user.id })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            await dispatch(emitEventToChangeClients(true));
          }
        }
      );
    }
  };

  const initialLoadOfUsers = async () => {
    setIsLoading(true);
    await dispatch(emitEventToChangeClients(false));

    if (user.role === "SALESMAN") {
      setIsLoading(false);
      await dispatch(emitEventToChangeClients(true));
    } else {
      await dispatch(getSalesmansRelatedToUser({ user_id: user.id })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setIsLoading(false);
            await dispatch(emitEventToChangeClients(true));
          }
        }
      );
    }
  };

  const cleanSelectedSalesmansToChangeParams = async () => {
    await dispatch(setSelectedSalesmansToChangeParams([]));
  };

  useEffect(() => {
    initialLoadOfUsers();
    breadcrumbsForSalesmanParams();
    cleanSelectedSalesmansToChangeParams();
    pageViewed(TrackingEvents.PROFILE_SALESMAN_CHANGE_PARAMS_PAGE);
  }, []);

  const salesmanColumns = useParamsOfSalesmansColumns(
    loadSalesmansRelatedToUser
  );

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$3",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {selectedSalesmansToChangeParams &&
        selectedSalesmansToChangeParams.length > 0 && (
          <Dialog open={dialogIsOpen} onOpenChange={(v) => setDialogIsOpen(v)}>
            <DialogTrigger>
              <FloatButtonToCreate />
            </DialogTrigger>
            <DialogContent style={{ maxHeight: "100vh" }}>
              {!isLoading ? (
                <>
                  <DialogClose>
                    <IconButton
                      size="small"
                      type="ghost"
                      css={{ position: "absolute", top: "1rem", right: "1rem" }}
                    >
                      <MdEdit fontSize="inherit" />
                    </IconButton>
                  </DialogClose>
                  <DialogTitle>
                    Alterar parâmetros de atendimento dos vendedores
                  </DialogTitle>

                  <ListDivider />

                  {selectedSalesmansToChangeParams.length <= 1 ? (
                    <ChangeParamsOfOneSalesmanForm
                      salesman={selectedSalesmansToChangeParams[0]}
                      onLoadSalesmansRelatedToUser={loadSalesmansRelatedToUser}
                      onCloseDialog={() => setDialogIsOpen(false)}
                    />
                  ) : (
                    <ParamsOfSalesmanForm
                      onLoadSalesmansRelatedToUser={loadSalesmansRelatedToUser}
                      onCloseDialog={() => setDialogIsOpen(false)}
                    />
                  )}
                </>
              ) : (
                <ClientsTableSkeletonPage />
              )}
            </DialogContent>
          </Dialog>
        )}

      <UserProfileHeaderToPage
        title="Parâmetros de atendimento dos vendedores"
        description="Selecione um vendedor, e altere suas configurações."
      />

      <ListDivider />

      <Box css={{ overflow: "hidden", width: "100%", height: "100%" }}>
        <ParamsOfSalesmansTable
          columns={salesmanColumns}
          data={salesmansRelatedToUser}
          isLoading={isLoading}
        />
      </Box>
    </Flex>
  );
}
